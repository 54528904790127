<template>
  <div class="activecode-gen">
    <Form
      ref="codeForm"
      :model="codeModel"
      :rules="codeRules"
      :label-position="lablePosition"
    >
      <FormItem prop="amount" label="数量">
        <i-input
          type="number"
          v-model="codeModel.amount"
          placeholder="请输入数量"
        >
        </i-input>
      </FormItem>
      <FormItem label="商家">
        <Select v-model="codeModel.merchantId" placeholder="请选择商家">
          <Option v-for="item in merchants" :value="item.id" :key="item.id">{{
            item.name
          }}</Option>
        </Select>
      </FormItem>
      <FormItem prop="expired_day" label="激活前有效天数">
        <i-input
          type="number"
          v-model="codeModel.expired_day"
          placeholder="请输入有效天数"
        >
        </i-input>
      </FormItem>
      <FormItem prop="effect_day" label="激活后有效天数">
        <i-input
          type="number"
          v-model="codeModel.effect_day"
          placeholder="请输入有效天数"
        >
        </i-input>
      </FormItem>
      <FormItem>
        <Button
          style="margin-top: 30px"
          type="primary"
          long
          @click="handleSubmit"
          >确认</Button
        >
      </FormItem>
    </Form>
  </div>
</template>

<script>
import { mixinDevice } from "@/util/mixin";
import api from "@/api";

export default {
  mixins: [mixinDevice],
  data() {
    const checkNums = (rule, value, callback) => {
      if (value <= 0) {
        callback(new Error("不能小于0"));
      } else {
        callback();
      }
    };
    return {
      codeModel: {
        amount: null,
        merchantId: null,
        expired_day: null,
        effect_day: null,
      },
      codeRules: {
        amount: [
          {
            required: true,
            message: "请输入数量",
            trigger: "blur",
          },
          { validator: checkNums, trigger: "blur" },
        ],
        expired_day: [
          {
            required: true,
            message: "请输入有效天数",
            trigger: "blur",
          },
          { validator: checkNums, trigger: "blur" },
        ],
        effect_day: [
          {
            required: true,
            message: "请输入有效天数",
            trigger: "blur",
          },
          { validator: checkNums, trigger: "blur" },
        ],
      },
      merchants:[],
    };
  },
  computed: {
    lablePosition() {
      return this.isMobile() ? "top" : "left";
    },
  },
  methods: {
    handleSubmit() {
      this.$refs["codeForm"].validate((valid) => {
        if (valid) {
          if(!this.codeModel.merchantId){
            this.$Message.error('请选择商家');
            return ;
          }
          api
            .activecodeCreate(
              this.codeModel.amount,
              this.codeModel.merchantId,
              this.codeModel.expired_day,
              this.codeModel.effect_day
            )
            .then((res) => {
                const link = document.createElement('a')
                let blob = new Blob([res.data], {type: 'application/x-xls'})
                link.style.display = 'none'
                link.href = URL.createObjectURL(blob)
                link.download = '激活码.xls'//res.headers['content-disposition'] //下载后文件名
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            })
            .catch((err) => {
              this.$Message.error(err.message);
            });
        } else {
          this.$Message.error("数据输入不正确！");
        }
      });
    },
    loadMerchants(){
      api.merchantList().then(res=>{
        this.merchants = res.merchants;
      }).catch(err=>{
        this.$Message.error("拉取商家数据失败: "+err.message);
      })
    }
  },
  mounted(){
    this.loadMerchants();
  }
};
</script>

<style lang="less" scoped>
.activecode-gen {
  width: 100%;
  max-width: 600px;
  padding: 12px;
  box-sizing: border-box;
}
</style>